import { set } from "mobx";

class GlobeManager {
  constructor(onGlobeReady) {
    this.globe = null;

    this.OnGlobeReady = onGlobeReady;

    this.state = {
      globeEl: null,
      dataPoints: [],
      visitedIndices: new Set(),
      gpuSupported: false,
      animationSpeed: 2000,
      rotateToNextCoord: 3000,
      animationStartTime: null,
      currentIndex: 0,
      temporaryGlobeStop: false,
      rotate: false,
      timer: null,
      globeTimerProcessTimer: null,
      targetCoodinates: { lat: 0, lng: 0, altitude: 1.4 },
    };
  }

  moveToCoordinate(lat, lng, duration) {
    //console.log("Moving to " + lat + " " + lng);
    if (!this.state.globeEl.current) {
      return;
    }

    this.state.targetCoodinates = { lat, lng, altitude: 1.4 };
    //alert("Moving to " + lat + " " + lng);

    this.state.globeEl.current.pointOfView(
      { lat, lng, altitude: 1.4 },
      duration
    );
  }

  onAnimationComplete() {
    //console.log("Animation complete!");
    if (this.state.temporaryGlobeStop || !this.state.rotate) {
      setTimeout(() => {
        this.onAnimationComplete();
      }, 400);
      //console.log("Temporary stop");
      return;
    }
    setTimeout(() => {
      //console.log("Animation complete! in settimeout");
      this.state.currentIndex = this.findNextIndex(this.state.currentIndex);
      //wait 3 seconds on the current point
      if (this.state.currentIndex >= 0) {
        const { lat, lng } =
          this.state.dataPoints[this.state.currentIndex].coordinates;
        //remove pending html element

        this.state.animationStartTime = Date.now();
        //this.state.setAllowInteraction(false);

        this.moveToCoordinate(lat, lng, this.state.animationSpeed);

        setTimeout(() => {
          this.state.setHtmlDataPoints([
            this.state.dataPoints[this.state.currentIndex],
          ]);
        }, 1000);
      }
      // requestAnimationFrame(() => {
      //   return this.checkifAnimationComplete(this);
      // });
    }, 3000);
  }

  checkifAnimationComplete(thisObj) {
    if (!thisObj.state.globeEl.current) {
      return;
    }
    if (!thisObj.state.gpuSupported) {
      //console.log("Animation complete!");
      //thisObj.onAnimationComplete();
      return true;
    }

    if (
      thisObj.state.targetCoodinates.lat === 0 &&
      thisObj.state.targetCoodinates.lng === 0
    ) {
      return true;
    }

    const currentCoordinates = thisObj.state.globeEl.current.pointOfView();
    if (
      Math.abs(currentCoordinates.lat - thisObj.state.targetCoodinates.lat) <
        0.01 &&
      Math.abs(currentCoordinates.lng - thisObj.state.targetCoodinates.lng) <
        0.01 &&
      Math.abs(
        currentCoordinates.altitude - thisObj.state.targetCoodinates.altitude
      ) < 0.01
    ) {
      //console.log("Animation complete!");
      //thisObj.onAnimationComplete();
      return true;
    } else {
      return false;
      // requestAnimationFrame(() => {
      //   return thisObj.checkifAnimationComplete(thisObj);
      // });
    }
  }

  calculateDistance(coord1, coord2) {
    const toRadians = (deg) => (deg * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRadians(coord2.lat - coord1.lat);
    const dLng = toRadians(coord2.lng - coord1.lng);
    const lat1 = toRadians(coord1.lat);
    const lat2 = toRadians(coord2.lat);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  }
  update(state) {
    this.state = { ...this.state, ...state };
  }
  reset() {
    this.state.currentIndex = 0;
    //clearTimeout(this.state.timer);
  }

  globeTimerProcess() {
    let completed = this.checkifAnimationComplete(this);

    if (completed) {
      this.onAnimationComplete();
      this.timer = setTimeout(() => {
        this.globeTimerProcess();
      }, this.state.rotateToNextCoord);
    } else {
      this.timer = setTimeout(() => {
        this.globeTimerProcess();
      }, 2000);
    }
  }

  rotateToNextCoordinate(setHtmlDataPoints, gpuSupported) {
    var self = this;

    clearTimeout(this.globeTimerProcessTimer);
    clearTimeout(this.timer);
    this.state.gpuSupported = gpuSupported;
    this.state.animationSpeed = gpuSupported ? 5000 : 0;
    this.state.rotateToNextCoord = gpuSupported ? 3000 : 6000;
    if (
      !this.state.globeEl ||
      !this.state.globeEl.current ||
      this.state.dataPoints.length === 0 ||
      this.state.temporaryGlobeStop
    ) {
      return;
    }
    this.state.setHtmlDataPoints = setHtmlDataPoints;
    //this.state.setAllowInteraction = setAllowInteraction;
    this.state.currentIndex = this.findNextIndex(this.state.currentIndex);
    setHtmlDataPoints([this.state.dataPoints[this.state.currentIndex]]);

    const { lat, lng } =
      this.state.dataPoints[this.state.currentIndex].coordinates;
    //remove pending html element

    this.moveToCoordinate(lat, lng, this.state.animationSpeed);

    this.globeTimerProcessTimer = setTimeout(() => {
      this.globeTimerProcess();
    }, 3000);

    //return and clear the timer
    return () => clearTimeout(this.globeTimerProcessTimer);
  }
  findNextIndex() {
    if (this.state.dataPoints.length === 0) {
      return 0;
    }
    const currentPoint =
      this.state.dataPoints[this.state.currentIndex].coordinates;

    // Get dataPoints array length
    const dataPointsLength = this.state.dataPoints.length;

    // Edge case: if there are very few data points, allow revisits sooner
    if (dataPointsLength <= 2) {
      this.state.visitedIndices.clear(); // Clear the set directly
    }

    // Get a random index
    let nextIndex = Math.floor(Math.random() * dataPointsLength);
    let attempt = 0; // Counter to prevent infinite loop

    // Try finding an unvisited index with a maximum number of attempts
    while (
      (this.state.visitedIndices.has(nextIndex) ||
        this.state.currentPointCountry ===
          this.state.dataPoints[nextIndex].countryName) &&
      attempt < 100 // Avoid infinite loops by limiting attempts
    ) {
      nextIndex = Math.floor(Math.random() * dataPointsLength);
      attempt++;
    }

    // If the loop ran too many times, reset visited indices
    if (attempt >= 100) {
      this.state.visitedIndices.clear(); // Reset visited indices
    }

    // Update current point country and mark this point as visited
    this.state.currentPointCountry =
      this.state.dataPoints[nextIndex].countryName;
    this.state.visitedIndices.add(nextIndex);

    // Keep the set within a manageable size, e.g., last half of the data points
    if (this.state.visitedIndices.size > dataPointsLength / 2) {
      this.state.visitedIndices.delete(
        Array.from(this.state.visitedIndices)[0]
      );
    }

    return nextIndex;
  }

  findNextIndex_old() {
    const currentPoint =
      this.state.dataPoints[this.state.currentIndex].coordinates;

    //get datapoints array length
    let dataPointsLength = this.state.dataPoints.length;
    //get random index

    let nextIndex = Math.floor(Math.random() * dataPointsLength);

    while (
      this.state.visitedIndices.has(nextIndex) ||
      this.state.currentPointCountry ===
        this.state.dataPoints[nextIndex].countryName
    ) {
      nextIndex = Math.floor(Math.random() * dataPointsLength);
    }
    this.state.currentPointCountry =
      this.state.dataPoints[nextIndex].countryName;
    // Mark this point as visited
    this.state.visitedIndices.add(nextIndex);
    // Keep the set within a manageable size, e.g., last 5 visited points
    if (this.state.visitedIndices.size > dataPointsLength / 2) {
      this.state.visitedIndices.delete(
        Array.from(this.state.visitedIndices)[0]
      );
    }

    return nextIndex;
  }
}

export default GlobeManager;
