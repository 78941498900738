import { makeAutoObservable, runInAction } from "mobx";
import TrxAmount from "../components/tooltips/ttTrxAmount";
import CheckoutSessions from "../components/tooltips/ttCheckoutSessions";
import NowOnCheckout from "../components/tooltips/ttNowOnCheckout";
import Transactions from "../components/tooltips/ttTransations";
import SvgIcon from "../components/common/SvgIcon";

class LiveViewHelper {
  merchantData = null;
  self = this;
  static doPolling = true;
  constructor() {
    this.merchantData = null;
    this.currencyInfo = {};
    this.isError = false;
    this.errorMessage = "";
    this.currenctCurrencyInfo = {};
    this.merchaantCurrencyInfo = {};
    this.debug = false;
    this.autoRefresh = false;
    this.userCultureCode = "en-US";
    this.currencyChangeFunc = null;
    this.isGlobeReady = false;
    this.POLL_INTERVAL = 5000;
    this.generalConfig = { initiated: false };
    this.globeConfig = {
      rotate: true,
      map: "transparent",
    };
    this.usdCurrencyInfo = {
      code: "USD",
      name: "United States Dollar",
      rate: 1,
      symbol: "$",
      cultureCode: "en-US",
    };
    this.loadFromStorage();
    makeAutoObservable(this);
  }
  loadFromStorage() {
    //load from local storage
    const globeConfig = localStorage.getItem("globeConfig");
    if (globeConfig) {
      runInAction(() => {
        this.globeConfig = JSON.parse(globeConfig);
      });
    }
  }
  onCurrencyChange(func) {
    runInAction(() => {
      this.currencyChangeFunc = func;
    });
  }
  setCurrencyChanged() {
    if (this.currencyChangeFunc) {
      this.currencyChangeFunc();
    }
  }
  updateState(data) {
    runInAction(() => {
      this.merchantData = data;
    });
  }
  documentReady() {}
  listenToIncomingMessages() {
    //wait for document to be ready
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", this.documentReady);
    } else {
      this.documentReady();
    }
    //window.addEventListener('message', this.handlePostMessage);
    //use arrow function to bind this
    window.addEventListener("message", (event) => {
      this.handlePostMessage(event, this);
    });

    const handleResize = (entries) => {
      for (let entry of entries) {
        // Log or perform an action when the height changes
        //console.log('Document height changed to:', entry.contentRect.height);

        this.handleResize();
      }
    };

    const observer = new ResizeObserver(handleResize);

    // Observe the body for changes in size
    observer.observe(document.body);

    this.handleResize();
  }

  getLatestOrdersData() {
    if (this.merchantData) {
      return this.merchantData.data.daily.latestOrders;
    }
  }
  getTransactionAmountByCountryData() {
    if (this.merchantData) {
      const labels = Object.keys(
        this.merchantData.data.daily.topCountriesByAmount
      );
      const values = [];
      const labelsArr = [];
      for (var key in labels) {
        let countryData =
          this.merchantData.data.daily.topCountriesByAmount[labels[key]];
        values.push(countryData.amount);
        labelsArr.push(countryData.name);
      }
 
      const { sortedA, sortedB } = this.sortDictionaryArrays(labelsArr, values);
      return {
        values: sortedB,
        labels: sortedA,
        title: "Top Countries Transactions Amount",
        format: {
          type: "currency",
          currency: this.currenctCurrencyInfo.code,
        },
      };
    }
    //return default data
    return {
      values: [],
      labels: [],
      title: "",
      format: {
        type: "currency",
        currency: this.currenctCurrencyInfo.code,
      },
    };
  }
  sortDictionaryArrays(A, B) {
    // Create an array of indices [0, 1, 2, ...]
    const indices = Array.from(B.keys());

    // Sort indices array based on the values in B in descending order
    indices.sort((i, j) => B[j] - B[i]);

    // Apply the sorting order of indices to arrays A and B
    const sortedA = indices.map((index) => A[index]);
    const sortedB = indices.map((index) => B[index]);
    return { sortedA, sortedB };
  }
  getTransactionCountByCountryData() {
    if (this.merchantData) {
      const labels = Object.keys(
        this.merchantData.data.daily.topCountriesByTransactions
      );
      const values = [];
      const labelsArr = [];
      for (var key in labels) {
        let countryData =
          this.merchantData.data.daily.topCountriesByTransactions[labels[key]];
        values.push(countryData.transactions);
        labelsArr.push(countryData.name);
      }

      const { sortedA, sortedB } = this.sortDictionaryArrays(labelsArr, values);
      return {
        values: sortedB,
        labels: sortedA,
        title: "Top Countries Transactions Count",
        format: {
          type: "number",
        },
      };
    }

    //return default data
    return {
      values: [],
      labels: [],
      title: "",
      format: {
        type: "currency",
        currency: "USD",
      },
    };
  }

  getKPIsInfo() {
    /*
      "currencyInfo": {
        "symbol": "¥",
        "name": "Yen",
        "code": "JPY",
        "rate": 3.1
    }
     */
    if (this.merchantData) {
      const dataArray = [
        {
          title: "Transactions Amount",
          value: this.formatAmount(this.merchantData.data.daily.totalAmount),
          tooltipComponent: (
            <TrxAmount offset={this.merchantData?.timezone?.gmtOffset} />
          ),
          iconPaths: [
            "M6 14C5.44772 14 5 14.4477 5 15V16C5 16.5523 5.44772 17 6 17H7C7.55228 17 8 16.5523 8 16V15C8 14.4477 7.55228 14 7 14H6Z",
            "M4 4H20C21.11 4 22 4.89 22 6V18C22 19.11 21.11 20 20 20H4C2.89 20 2 19.11 2 18L2.01 6C2.01 4.89 2.89 4 4 4ZM5 18H19C19.55 18 20 17.55 20 17V11H4V17C4 17.55 4.45 18 5 18ZM4 8H20V6H4V8Z",
          ],
        },
        {
          title: "Checkout Sessions",
          value: this.formatAmount(
            this.merchantData.data.checkoutSessions.totalCheckouts,
            "number"
          ),
          tooltipComponent: (
            <CheckoutSessions offset={this.merchantData?.timezone?.gmtOffset} />
          ),
          iconPaths: [
            "M19 4.5H5C3.89 4.5 3 5.4 3 6.5V18.5C3 19.6 3.89 20.5 5 20.5H19C20.1 20.5 21 19.6 21 18.5V6.5C21 5.4 20.11 4.5 19 4.5ZM19 18.5H5V8.5H19V18.5ZM7 6.5C7 7.05228 6.55228 7.5 6 7.5C5.44772 7.5 5 7.05228 5 6.5C5 5.94772 5.44772 5.5 6 5.5C6.55228 5.5 7 5.94772 7 6.5ZM9 7.5C9.55228 7.5 10 7.05228 10 6.5C10 5.94772 9.55228 5.5 9 5.5C8.44772 5.5 8 5.94772 8 6.5C8 7.05228 8.44772 7.5 9 7.5Z",
          ],
        },
        {
          title: "Now on Checkout",
          value: this.formatAmount(
            this.merchantData.data.activeCheckoutSessions.count,
            "number"
          ),
          tooltipComponent: <NowOnCheckout />,
          iconPaths: [
            "M5.01 20C5.01 18.9 5.9 18 7 18C8.1 18 9 18.9 9 20C9 21.1 8.1 22 7 22C5.9 22 5.01 21.1 5.01 20Z",
            "M15.01 20C15.01 18.9 15.9 18 17 18C18.1 18 19 18.9 19 20C19 21.1 18.1 22 17 22C15.9 22 15.01 21.1 15.01 20Z",
            "M15.55 13H8.1L7 15H18C18.55 15 19 15.45 19 16C19 16.55 18.55 17 18 17H7C5.48 17 4.52 15.37 5.25 14.03L6.6 11.59L3 4H2C1.45 4 1 3.55 1 3C1 2.45 1.45 2 2 2H4.27L8.53 11H15.55L18.0387 6.3H10.6001C10.0501 6.3 9.6001 5.85 9.6001 5.3C9.6001 4.75 10.0501 4.3 10.6001 4.3H19.6001C19.9052 4.3 20.1795 4.43846 20.3633 4.65564C20.6667 4.95671 20.7462 5.43416 20.54 5.83L17.3 11.97C16.96 12.59 16.3 13 15.55 13Z",
          ],
        },
        {
          title: "Transactions Count",
          value: this.formatAmount(
            this.merchantData.data.daily.totalTransactions,
            "number"
          ),
          tooltipComponent: (
            <Transactions offset={this.merchantData?.timezone?.gmtOffset} />
          ),
          iconPaths: [
            "M18 6.5H16C16 4.29 14.21 2.5 12 2.5C9.79 2.5 8 4.29 8 6.5H6C4.9 6.5 4 7.4 4 8.5V20.5C4 21.6 4.9 22.5 6 22.5H18C19.1 22.5 20 21.6 20 20.5V8.5C20 7.4 19.1 6.5 18 6.5ZM12 4.5C13.1 4.5 14 5.4 14 6.5H10C10 5.4 10.9 4.5 12 4.5ZM18 20.5H6V8.5H8V10.5C8 11.05 8.45 11.5 9 11.5C9.55 11.5 10 11.05 10 10.5V8.5H14V10.5C14 11.05 14.45 11.5 15 11.5C15.55 11.5 16 11.05 16 10.5V8.5H18V20.5Z",
          ],
        },
      ];

      return dataArray;
    }

    return [];
  }

  getProducts() {
    if (this.merchantData) {
      for (var key in this.merchantData.data.topProducts) {
        let product = this.merchantData.data.products[key];
        product.quantity = this.formatAmount(product.price, "number");
      }
      return this.merchantData.data.topProducts;
    }
    return [];
  }
  releaseListener() {
    window.removeEventListener("message", this.handlePostMessage);
  }
  updateConfig(config) {
 
      runInAction(() => {
          this.generalConfig = config;
          this.generalConfig.initiated  = true;
      });
  }
  async saveConfig(user, password) {
    //post `${process.env.REACT_APP_SERVER_BASE_URL}/saveConfig`
    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/saveConfig`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ config : this.generalConfig, user, password }),
    });

    const data = await response.json();
    return data;
  }
  async getConfig() {
      const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/getConfig`); 
      const data = await response.json();
      this.updateConfig(data);     
  }
  init() {
    this.getConfig();
    this.listenToIncomingMessages();

    //return promise
    //post a READY message to the parent window
    window.parent.postMessage(
      { type: "LIVE_VIEW_MESSAGE", message: "READY" },
      "*"
    );
    return new Promise((resolve, reject) => {
      //wait for key and paylod to be ready
      const si = setInterval(() => {
        if (this.encryptedData && this.keyType) {
          clearInterval(si);
          //console.log("Data Ready");
          this.fetchMerchantData().then((data) => {
            if (data.error) {
              runInAction(() => {
                this.errorMessage = data.error;
                this.isError = true;
              });
            } else {
              this.updateState(data);
              this.handleResize();
              this.pollData();
              runInAction(() => {
                this.isError = false;
                this.errorMessage = "";
              });
            }
            //console.log("Data Fetched");
            //resolve(data);
          });

          //resolve(this.merchantData);
        }
      }, 1000);
    });
  }
  pollData() {
    if (LiveViewHelper.doPolling) {
      this.fetchMerchantData().then((data) => {
        if (data.error) {
          this.errorMessage = data.error;
          this.isError = true;
        } else {
          this.updateState(data);
          this.handleResize();

          setTimeout(() => {
            this.pollData();
          }, this.POLL_INTERVAL);
        }
      });
    }
  }

  handleResize() {
    //post messate to container
    // Get the iframe's content size
    var width = document.documentElement.scrollWidth;
    var height = document.documentElement.scrollHeight;

    window.parent.postMessage(
      {
        type: "LIVE_VIEW_MESSAGE",
        message: "RESIZE",
        dims: { width: width, height: height },
      },
      "*"
    );
  }
  handlePostMessage(event, self) {
    if (
      event.origin !== window.location.origin &&
      event.origin.indexOf("bglobale") === -1 &&
      event.origin.indexOf("global-e") === -1 &&
      event.origin.indexOf("live-dashboard") === -1
    ) {
      // Ensure the message is from a trusted source
      return;
    }

    if (event.data.type !== "ENCRYPTED_DATA") {
      // Ignore messages that are not of the expected type
      return;
    }
    runInAction(() => {
      self.encryptedData = event.data.key;
      self.keyType = event.data.keytype;
      self.currencyInfo = event.data.currencyInfo;
      self.debug = event.data.debug;
      self.autoRefresh = event.data.autoRefresh;

      self.source = event.data.source;
    });
    //console.log('Decrypted Data:', encryptedData);
    //self.fetchMerchantData();
  }

  fetchMerchantData = async (encryptedKey, keyType) => {
    //alert(process.env.REACT_APP_SERVER_BASE_URL);
    const url = `${process.env.REACT_APP_SERVER_BASE_URL}/merchantdata/${this.source}`;
    var data = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: this.encryptedData,
        keyType: this.keyType,
        currencyInfo: this.currencyInfo,
      }),
    });
    //console.log("Fetching data in fetchMerchantData");
    var response = await data.json();
    if (response.error) {
      return response;
    }
    //set currency info first time only
    if (Object.keys(this.currenctCurrencyInfo).length === 0) {
      runInAction(() => {
        this.currenctCurrencyInfo = response.currencyInfo;
        this.merchaantCurrencyInfo = response.currencyInfo;      
        this.userCultureCode = response.userCultureCode;
      });
    }

    return response;
  };
  toggleCurrency() {
    runInAction(() => {
      if (this.currenctCurrencyInfo.code === "USD") {
        this.currenctCurrencyInfo = this.merchaantCurrencyInfo;
      } else {
        this.currenctCurrencyInfo = this.usdCurrencyInfo;
      }
    });
  }
  getNewObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  toggleGlobeRotation() {
    runInAction(() => {
      this.globeConfig.rotate = !this.globeConfig.rotate;
      this.globeConfig = this.getNewObject(this.globeConfig);

      //save in local storage
      localStorage.setItem("globeConfig", JSON.stringify(this.globeConfig));
    });
  }

  setGpuTestPerformed(isSupported) {
    runInAction(() => {
      this.globeConfig.gpuTestPerformed = true;
      this.globeConfig.isGpuSupported = isSupported;
      localStorage.setItem("globeConfig", JSON.stringify(this.globeConfig));

      //first new objecy
      this.globeConfig = this.getNewObject(this.globeConfig);
    });
  }

  setIsGlobeReady(isReady) {
    runInAction(() => {
      this.isGlobeReady = isReady;
    });
  }
  formatAmount(amount, type = "currency", forceUsd = false, noConvert = false) {
    let currencyToUse = forceUsd
      ? this.usdCurrencyInfo
      : this.currenctCurrencyInfo;

    if (!currencyToUse || Object.keys(currencyToUse).length === 0) {
      currencyToUse = this.usdCurrencyInfo;
    }

    
    try {
      let amountToShow = amount / currencyToUse.rate;
      let fractionDigitis = amountToShow <= 10 ? 2 : 0;
      if (noConvert && type === "currency") {
        fractionDigitis = amount <= 10 ? 2 : 0;
        return new Intl.NumberFormat(this.userCultureCode, { //currencyToUse.cultureCode
          style: "currency",
          minimumFractionDigits: fractionDigitis, // No decimal places
          maximumFractionDigits: fractionDigitis, // No decimal places
          currency: currencyToUse.code,
          currencyDisplay: "narrowSymbol",
        }).format(amount);
      }

      if (type === "currency" && currencyToUse.code !== null) {
   
       
        return new Intl.NumberFormat(this.userCultureCode, { //currencyToUse.cultureCode
          style: "currency",
          minimumFractionDigits: fractionDigitis, // No decimal places
          maximumFractionDigits: fractionDigitis, // No decimal places
          currency: currencyToUse.code,
          currencyDisplay: "narrowSymbol",
        }).format(amountToShow);
      }
      //count            
      //if amount is not a whole number return null
      if (amount % 1 !== 0) {
        return "";
      }
      return new Intl.NumberFormat(currencyToUse.cultureCode).format(amount);
    } catch (e) {
      console.error("Error in formatAmount", e);
      debugger;
      return amount;
    }
  }
}

const state = new LiveViewHelper();
export { state };
export { LiveViewHelper };
